import * as actionTypes from "../actions/constant/actionTypes";

const initialState = {
  userSession: {},
  isAuthenticated: false,
  usersTable: {},
  currentlyClickedUser: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.USER_SESSION:
      let isAuthenticated;
      action.userSession.status
        ? (isAuthenticated = true)
        : (isAuthenticated = false);
      const { userSession } = action;
      return { ...state, userSession, isAuthenticated };
    case actionTypes.USERS_TABLE:
      const { usersTable } = action;
      usersTable.data.data = usersTable.data?.data?.map((user) => {
        return { ...user, dob: user?.dob?.split("T")[0] };
      });
      return { ...state, usersTable: usersTable.data };
    case actionTypes.RESET_USER_SESSION_MESSAGE:
      const resetUserSession = {
        ...state.userSession,
        status: null,
        message: "",
      };
      return { ...state, userSession: resetUserSession };

    case actionTypes.SET_CURRENTLY_CLICKED_USER:
      return { ...state, currentlyClickedUser: action.currentClickedUser };
    default:
      return state;
  }
}
