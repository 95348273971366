import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Tabs, Tab, Box } from '@material-ui/core';
import makeStyles from './styles';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import EditUser from '../EditUser/EditUser';
import UserMedicines from './UserMedicines/UserMedicines';
import { isUndefined } from 'lodash';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const UserDetails = (props) => {
  useEffect(() => {
    if (isUndefined(props.location.state)) {
      props.history.goBack();
    }
  }, []);
  const classes = makeStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      {!isUndefined(props.location.state) ? (
        <div>
          <Header history={props.history} />
          <Sidebar />
          <div className={classes.paper} style={{ marginLeft: '20%' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Prescription" {...a11yProps(1)} />
            </Tabs>
          </div>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <EditUser id={props.location.state.id} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <UserMedicines id={props.location.state.id} />
          </TabPanel>
        </div>
      ) : null}
    </React.Fragment>
  );
};
export default UserDetails;
