import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import { LocalHospital, ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import SidebarLink from './components/SidebarLink/SidebarLink';
// import Dot from "./components/Dot";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';

const structure = [
  {
    id: 3,
    label: 'Clients',
    link: '/users',
    icon: <SupervisedUserCircleIcon />,
  },
  { id: 2, label: 'Medicines', link: '/medicines', icon: <LocalHospital /> },
];

function Sidebar(props) {
  var classes = useStyles();
  var theme = useTheme();

  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: props.isSidebarOpened,
        [classes.drawerClose]: !props.isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: props.isSidebarOpened,
          [classes.drawerClose]: !props.isSidebarOpened,
        }),
      }}
      open={props.isSidebarOpened}>
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => props.toggleSidebar()}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={props.location}
            isSidebarOpened={props.isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

function mapStateToProps(state) {
  const { isSidebarOpened } = state.layout;
  return {
    isSidebarOpened,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSidebar: bindActionCreators(actions.toggleSidebar, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
