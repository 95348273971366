import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import makeStyles from '../styles';

//Redux
import { useDispatch } from 'react-redux';
import * as actions from '../../../redux/actions';
import SnackBar from '../../SnackBar/SnackBar';
import Form from '../Form/Form';

const EditMedicine = (props) => {
  const classes = makeStyles();
  const dispatch = useDispatch();

  //states
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [genericName, setGenericName] = useState('');
  const [mgRange, setMgRange] = useState('');
  const [medicineId, setMedicineId] = useState('');

  useEffect(() => {
    let medicine = props.medicineData;
    setName(medicine?.name);
    setDescription(medicine?.description);
    setGenericName(medicine?.generic_name);
    setMgRange(medicine?.mg_range);
    setMedicineId(medicine?.id);
  }, []);

  const handelChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'description':
        setDescription(value);
        break;
      case 'genericName':
        setGenericName(value);
        break;
      case 'mgRange':
        setMgRange(value);
      default:
        return;
    }
  };

  const editMedicine = (event) => {
    event.preventDefault();
    let medicineData = {
      name: name?.toString(),
      description: description?.toString(),
      generic_name: genericName?.toString(),
      mg_range: mgRange?.toString(),
      category: 'Tab',
    };
    dispatch(actions.editMedicine(medicineId, medicineData));
    props.handelClose();
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h6"
        align="left"
        color="primary"
        style={{ marginLeft: 10 }}
      >
        Edit Medicine Details
      </Typography>
      <Grid container direction="row">
        <Grid item sm={12}>
          <Form
            name={name}
            description={description}
            genericName={genericName}
            mgRange={mgRange}
            handelChange={handelChange}
            submit={editMedicine}
          />
          <SnackBar />
        </Grid>
      </Grid>
    </div>
  );
};

export default EditMedicine;
