export const SERVICE_REQUEST = 'SERVICE_REQUEST';
export const SERVICE_SUCCESS = 'SERVICE_SUCCESS';
export const SERVICE_ERROR = 'SERVICE_ERROR';
export const API_RESPONSE = 'API_RESPONSE';
export const RESET_API_RESPONSE = 'RESET_API_RESPONSE';
export const USER_SESSION = 'USER_SESSION';
export const RESET_USER_SESSION_MESSAGE = 'RESET_USER_SESSION_MESSAGE';
export const USER_ADDRESS = 'USER_ADDRESS';
export const USERS_TABLE = 'USERS_TABLE';
export const ADD_USERS = 'ADD_USERS';
// export const EDIT_USERS = 'EDIT_USERS';
// export const DELETE_USERS = 'DELETE_USERS';
export const BUSINESS_NATURE_IDS = 'BUSINESS_NATURE_IDS';
export const SIDEBAR = 'SIDEBAR';
export const MEDICINES = 'MEDICINES';
// export const EDIT_MEDICINES = 'EDIT_MEDICINES';
// export const DELETE_MEDICINES = 'DELETE_MEDICINES';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';
export const GET_CITIES = 'GET_CITIES';
export const GET_USER_MEDICINES = 'GET_USER_MEDICINES';
export const ADD_USER_MEDICINES = 'ADD_USER_MEDICINES';
export const DELETE_USER_MEDICINES = 'DELETE_USER_MEDICINES';
export const SEARCH_MEDICINES = 'SEARCH_MEDICINES';
export const GET_MG_RANGES = 'GET_MG_RANGES';
export const RESET_SEARCHED_MEDICINE_DATA = 'RESET_SEARCHED_MEDICINE_DATA';
export const SET_CURRENTLY_CLICKED_USER = 'SET_CURRENTLY_CLICKED_USER';
