import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid grey',
    borderRadius: 15,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    width: '65%',
    margin: '5% 0 0 5%',
    outline: 'none',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
