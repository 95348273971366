module.exports = {
  MEDICINE_ITEMS: '/api/admin/meds',
  EDIT_MEDICINE: '/api/admin/meds/',
  DELETE_MEDICINE: '/api/admin/meds/',
  USER_SESSION: '/api/admin/users/login',
  USERS_TABLE:
    '/api/admin/users?sort_by=asc&sort_on=last_name&user_type=2&perPage=100000',
  RESET_USER: '/api/admin/user/reset',
  ADD_USERS: '/api/admin/users',
  EDIT_USERS: '/api/admin/users/',
  DELETE_USERS: '/api/admin/users/deleteUser/',
  ADD_USER_PAYMENT: '/api/admin/payment/addPayment',
  GET_COUNTRIES: '/api/data/getCountries',
  GET_STATES: '/api/data/getStates?',
  GET_CITIES: '/api/data/getCities?',
  GET_USER_MEDICINES: '/api/admin/meds/listUserMedicines?',
  ADD_USER_MEDICINES: '/api/admin/meds/addUpdateUserMedicines',
  DELETE_USER_MEDICINES: '/api/admin/meds/deleteUserMedicine',
  SEARCH_MEDICINES: '/api/admin/meds/searchMedicine?',
  GET_MG_RANGES: '/api/admin/meds/getMgRange?medicine_name=',
  RESET_USER_PASSWORD: '/api/admin/users/',
};
