import axios from "axios";
import * as actionTypes from "./constant/actionTypes";
import { getJWT } from "../../utils/storeUtils";
import { persistor } from "../../stores/configureStore";
import { getUsersTable } from "./usersAction";

const api = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
});
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      persistor.purge();
      persistor.pause();
      window.location.replace("/login");
    } else {
      window.location.replace("/error");
    }
  }
);

export default api;

export function restApiResponse() {
  return {
    type: actionTypes.RESET_API_RESPONSE,
  };
}

function request() {
  return {
    type: actionTypes.SERVICE_REQUEST,
    payload: { isLoading: true, failed: false },
  };
}

function success() {
  return {
    type: actionTypes.SERVICE_SUCCESS,
    payload: { isLoading: false, failed: false },
  };
}

function failure() {
  return {
    type: actionTypes.SERVICE_ERROR,
    payload: { isLoading: false, failed: true },
  };
}

function header() {
  return getJWT();
}

//Currently I have only constructed the get and the post generic calls, please follow the same
//scheme for delete and put as well

export function get(url, params, target, redirect) {
  if (header() !== "") {
    api.defaults.headers.common = {
      Authorization: header(),
      "Content-Type": "application/json",
    };
  }
  return (dispatch) => {
    dispatch(request());
    return api
      .get(url + params)
      .then((response) => {
        dispatch(target(response.data));
        dispatch(success());
        if (redirect !== null) {
          // dispatch(push(redirect));
        }
      })
      .catch((error) => {
        dispatch(failure());
        throw error;
      });
  };
}

export function post(url, json, target, redirect, history = null) {
  if (header() !== "") {
    api.defaults.headers.common = {
      Authorization: header(),
      "Content-Type": "application/json",
    };
  }
  return (dispatch) => {
    dispatch(request());
    return api
      .post(url, json)
      .then((response) => {
        dispatch(target(response.data));
        dispatch(success());
        if (history !== null) {
          history.push(redirect);
        }
      })
      .catch((error) => {
        dispatch(failure());
        throw error;
      });
  };
}

export function put(url, id, json, target, redirect, history = null) {
  if (header() !== "") {
    api.defaults.headers.common = {
      Authorization: header(),
      "Content-Type": "application/json",
    };
  }
  return (dispatch) => {
    dispatch(request());
    return api
      .put(url + id, json)
      .then((response) => {
        dispatch(target(response.data));
        dispatch(getUsersTable());
        dispatch(success());
        if (history !== null) {
          history.push(redirect);
        }
      })
      .catch((error) => {
        dispatch(failure());
        throw error;
      });
  };
}

export function clear(url, json, redirect, history = null) {
  if (header() !== "") {
    api.defaults.headers.common = {
      Authorization: header(),
      "Content-Type": "application/json",
    };
  }
  return (dispatch) => {
    dispatch(request());
    return api
      .put(url, json)
      .then(() => {
        // dispatch(target(response.data));
        dispatch(getUsersTable());
        dispatch(success());
        if (history !== null) {
          history.push(redirect);
        }
      })
      .catch((error) => {
        dispatch(failure());
        throw error;
      });
  };
}

export function deleteApi(url, id, json, target, redirect, history = null) {
  if (header() !== "") {
    api.defaults.headers.common = {
      Authorization: header(),
      "Content-Type": "application/json",
    };
  }
  return (dispatch) => {
    dispatch(request());
    return api
      .delete(url + id, { data: json })
      .then((response) => {
        dispatch(target(response.data));
        dispatch(success());
        if (history !== null) {
          history.push(redirect);
        }
      })
      .catch((error) => {
        dispatch(failure());
        throw error;
      });
  };
}
