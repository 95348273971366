import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../../stores/configureStore';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import Themes from '../../themes';
import PrivateRoute from './Routes/PrivateRoutes';
import PublicRoute from './Routes/PublicRoutes';
import { privateRoutes, publicRoutes } from './Routes/Routes';

const Root = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <BrowserRouter>
            <div>
              <Switch>
                {
                  publicRoutes.map((route, index) => {
                    return (
                      <PublicRoute key={index} exact={route.exact} path={route.path} component={route.component} />
                    )
                  })
                }
                {
                  privateRoutes.map((route, index) => {
                    return (
                      <PrivateRoute key={index} exact={route.exact} path={route.path} component={route.component} />
                    )
                  })
                }
                <Route path="*" component={() => "404 NOT FOUND"} />
              </Switch>
            </div>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default Root;
