import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Button,TextField, InputAdornment } from '@material-ui/core';
import { EditTwoTone, DeleteForeverTwoTone ,SearchOutlined } from '@material-ui/icons';
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';
import Table from '../../Table/Table';
import SnackBar from '../../SnackBar/SnackBar';
import AddMedicineForm from '../AddMedicine/AddMedicineForm';
import Modals from '../../Modal/Modals';
import EditMedicine from '../EditMedicine/EditMedicine';
import DeleteMedicine from '../DeleteMedicine/DeleteMedicine';
import { capitalize } from 'lodash';
const MedicinesTable = (props) => {
  const dispatch = useDispatch();
  const { medicineItems } = useSelector((state) => state.medicines);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [editMedicine, setEditMedicine] = useState(false);
  const [deleteMedicine, setDeleteMedicine] = useState(false);
  const [searchMedicine, setSearchMedicine]=useState(null)
  const getMedicineTableData = useCallback(() => {
    dispatch(actions.getMedicines());
  }, [dispatch]);

  const deleteDispatch = () => {
    dispatch(actions.deleteMedicine(selectedData));
  };

  const handelClose = () => {
    setTimeout(() => {
      setOpen(false);
      setEditMedicine(false);
      setDeleteMedicine(false);
    }, 500);
  };

  useEffect(() => {
    if (!open) {
      getMedicineTableData();
    }
  }, [getMedicineTableData, open]);
  useEffect(() => {
    if (editMedicine) {
      setSelectedData(medicineItems.data[currentIndex]);
      setOpen(true);
    }
  }, [currentIndex, editMedicine, medicineItems.data]);
  useEffect(() => {
    if (deleteMedicine) {
      setSelectedData(medicineItems?.data[currentIndex]?.id);
      setOpen(true);
    }
  }, [currentIndex, deleteMedicine, medicineItems]);

  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      cellStyle: { textAlign: 'left' },
      valueFormatter: params => capitalize(params.data.name) 

    },
    {
      headerName: 'Generic Name',
      field: 'generic_name',
      cellStyle: { textAlign: 'left', paddingLeft: '2%' },
    },
    {
      headerName: 'Dosage',
      field: 'mg_range',
      cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Actions',
      cellRendererFramework: (params) => (
        <React.Fragment>
          <EditTwoTone
            fontSize="small"
            style={{ color: 'blue', cursor: 'pointer', marginRight: 20 }}
            onClick={() => {
              setCurrentIndex(params.rowIndex);
              setEditMedicine(true);
            }}
          />
          <DeleteForeverTwoTone
            fontSize="small"
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => {
              setCurrentIndex(params.rowIndex);
              setDeleteMedicine(true);
            }}
          />
        </React.Fragment>
      ),
      cellStyle: { cursor: 'default', paddingRight: '20%' },
    },
  ];
  const rows = [];
  if (medicineItems !== undefined && 'data' in medicineItems) {
    medicineItems.data.map((data) => {
      rows.push({
        name: data.name,
        generic_name: data.generic_name,
        mg_range: data.mg_range,
        category: data.category,
        id: data.id,
      });
    });
  }
  const defaultColDef = { resizable: true }
  let modalData = <AddMedicineForm handelClose={handelClose} />;
  if (editMedicine) {
    modalData = (
      <EditMedicine medicineData={selectedData} handelClose={handelClose} />
    );
  }
  if (deleteMedicine) {
    modalData = (
      <DeleteMedicine dispatch={deleteDispatch} handelClose={handelClose} />
    );
  }
  return (
    <div>
      <Header history={props.history} />
      <Sidebar />
      <Modals open={open} handelClose={handelClose} modalData={modalData} />
      <div
        style={{
          marginLeft: '10%',
          marginRight: '10%',
          marginTop: '7.5%',
          width: '85%',
        }}
      >
        <h2 style={{ textAlign: 'left' }}>
          Medicines List
          <Button
            style={{ float: 'right' }}
            color="primary"
            variant="outlined"
            onClick={() => setOpen(true)}
          >
            Add Medicine
          </Button>
          <TextField
            placeholder="Search Medicine"
            variant="outlined"
            type="search"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            style={{ float: 'right', marginRight: '1%' }}
            onChange={(event) => setSearchMedicine(event.target.value)}
          />
        </h2>
        <Table
          rows={rows}
          columns={columns}
          defaultColDef={defaultColDef}
          searchText={searchMedicine}
        />
        <SnackBar />
      </div>
    </div>
  );
};
export default MedicinesTable;
