import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu } from '@material-ui/core';
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import classNames from 'classnames';

// styles
import useStyles from './styles';
import { get } from 'lodash';

// components
import { Typography } from '../Wrappers/Wrappers';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';

const Header = (props) => {
  var classes = useStyles();

  // global
  const first_name = get(props.userSession, 'first_name', '');
  const last_name = get(props.userSession, 'last_name', '');

  // local
  var [profileMenu, setProfileMenu] = useState(null);

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color='inherit'
          onClick={() => props.toggleSidebar(!props.isSidebarOpened)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}>
          {props.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant='h6' weight='medium' className={classes.logotype}>
          HelpMyMedicare
        </Typography>
        <div className={classes.grow} />
        <IconButton
          aria-haspopup='true'
          color='inherit'
          className={classes.headerMenuButton}
          aria-controls='profile-menu'
          onClick={(e) => setProfileMenu(e.currentTarget)}>
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id='profile-menu'
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem>
          <div className={classes.profileMenuUser}>
            <Typography variant='h6' weight='medium'>
              {first_name} {last_name}
            </Typography>
          </div>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color='primary'
              onClick={() => props.logoutUser(props.history)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

function mapStateToProps(state) {
  const { isSidebarOpened } = state.layout;
  const { userSession } = state.users;
  return {
    isSidebarOpened,
    userSession,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSidebar: bindActionCreators(actions.toggleSidebar, dispatch),
    logoutUser: bindActionCreators(actions.logoutUser, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
