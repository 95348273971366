import React from 'react';
import { Modal, Fade, Backdrop } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import makeStyles from './styles';

const Modals = (props) => {
  const classes = makeStyles();

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={props.handelClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <CloseOutlined
            fontSize="small"
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              props.handelClose();
            }}
          />
          {props.modalData}
        </div>
      </Fade>
    </Modal>
  );
};

export default Modals;
