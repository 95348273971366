import { get, post, put, deleteApi } from './api';
import * as actionTypes from './constant/actionTypes';
import * as EndPoint from './constant/EndPoint';

export function apiResponse(responseData) {
  return {
    type: actionTypes.API_RESPONSE,
    payload: {
      status: responseData.status,
      message: responseData.message,
    },
  };
}

export function medicineTable(medicineItems) {
  return {
    type: actionTypes.MEDICINES,
    medicineItems: medicineItems,
  };
}

export function getMedicines() {
  return get(
    EndPoint.MEDICINE_ITEMS + '?unique_by_name=no',
    '',
    medicineTable,
    null
  );
}

export function addMedicine(medicineData) {
  let params = medicineData;
  return post(EndPoint.MEDICINE_ITEMS, params, apiResponse, null);
}
export function editMedicine(id, medicineData) {
  let params = medicineData;
  return put(EndPoint.EDIT_MEDICINE, id, params, apiResponse);
}
export function deleteMedicine(id, history) {
  return deleteApi(
    EndPoint.DELETE_MEDICINE,
    id,
    '',
    apiResponse,
    '/medicines',
    history
  );
}
