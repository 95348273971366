import * as actionTypes from '../actions/constant/actionTypes';

const initialState = {
  medicines: [],
  searchedMedicines: [],
  mgRanges: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_MEDICINES:
      const { medicines } = action;
      return { ...state, medicines: medicines.data };
    case actionTypes.SEARCH_MEDICINES:
      const { searchedMedicines } = action;
      return { ...state, searchedMedicines: searchedMedicines.data };
    case actionTypes.GET_MG_RANGES:
      const { mgRanges } = action;
      return { ...state, mgRanges: mgRanges.data };
    case actionTypes.RESET_SEARCHED_MEDICINE_DATA:
      return { ...state, searchedMedicines: [], mgRanges: [] };
    default:
      return state;
  }
}
