import { combineReducers } from 'redux';
import usersReducer from './usersReducer';
import serviceReducer from './serviceReducer';
import layoutReducer from './layoutReducer';
import medicinesReducer from './medicinesReducer';
import dataServicesReducer from './dataServicesReducer';
import userMedicinesReducer from './userMedicinesReducer';

export default combineReducers({
  users: usersReducer,
  service: serviceReducer,
  layout: layoutReducer,
  medicines: medicinesReducer,
  dataServices: dataServicesReducer,
  userMedicines: userMedicinesReducer,
});

//Be mindful of reducer keys through which you will access the respective reducers state
//Also be mindful of not over populating a reducer, keep seperate reducers for separete states
