import { store } from '../stores/configureStore';

export const getJWT = () => {
  const { users } = store.getState();
  const { userSession } = users;
  if (userSession.data) {
    const { token } = userSession.data.user;
    return `Bearer ${token}`;
  } else {
    return '';
  }
};
