import React, { useState, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../redux/actions';

const SnackBar = (props) => {
  const { apiResponse } = useSelector((state) => state.service);
  const dispatch = useDispatch();

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [alertTitle, setAlertTitle] = useState('');

  useEffect(() => {
    if (!_.isEmpty(apiResponse)) {
      if (apiResponse.status) {
        setAlertMessage(apiResponse.message);
        setAlertType('success');
        setAlertTitle('SUCCESS');
      } else {
        setAlertMessage(
          props?.alertMessage ? props?.alertMessage : apiResponse.message
        );
        setAlertType('error');
        setAlertTitle('ERROR');
      }
      setAlertOpen(true);
    }
  }, [apiResponse]);
  const handleClose = () => {
    setAlertOpen(false);
    dispatch(actions.restApiResponse());
  };
  return (
    <Snackbar
      open={alertOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
      style={{ textAlign: 'start' }}
    >
      <Alert onClose={handleClose} severity={alertType}>
        <AlertTitle>{alertTitle}</AlertTitle>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
