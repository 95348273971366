import { get, post, put, deleteApi, clear } from './api';
import * as actionTypes from './constant/actionTypes';
import * as EndPoint from './constant/EndPoint';
import { persistor, store } from '../../stores/configureStore';

export function processLoginCredentials(userSession) {
  return {
    type: actionTypes.USER_SESSION,
    userSession: userSession,
  };
}

export function usersTable(usersTable) {
  return {
    type: actionTypes.USERS_TABLE,
    usersTable: usersTable,
  };
}
export function setCurrentlyClickedUser(currentClickedUser) {
  return {
    type: actionTypes.SET_CURRENTLY_CLICKED_USER,
    currentClickedUser:currentClickedUser
  };
}
export function resetUserSessionMessage() {
  return {
    type: actionTypes.RESET_USER_SESSION_MESSAGE,
  };
}

export function apiResponse(responseData) {
  return {
    type: actionTypes.API_RESPONSE,
    payload: {
      status: responseData.status,
      message: responseData.message,
    },
  };
}

export function logoutUser(history) {
  return (dispatch) => {
    persistor.pause();
    dispatch(processLoginCredentials({}));
    persistor.purge();
    localStorage.removeItem('recentUsers');
    history.push('/login');
  };
}

export function loginUser(username, password, history) {
  let params = {
    email: username.toString(),
    password: password.toString(),
  };
  persistor.persist();
  return post(
    EndPoint.USER_SESSION,
    params,
    processLoginCredentials,
    '/users',
    history
  );
}

export function addNewUser(userData) {
  let params = userData;
  return post(EndPoint.ADD_USERS, params, apiResponse, null);
}
export function editUser(id, userData) {
    let params = userData;
    const { usersTable:usersData } = store.getState().users;
    const changed_data =  usersData.data.map((element,index) => {
      if(element.id === id){
        return {id,...userData}
      }
      return element
    });
    usersData.data = changed_data
    store.dispatch(usersTable({data:usersData}))
    return put(EndPoint.EDIT_USERS, id, params, apiResponse, '/users');
}

export function resetUserPassword(id, password) {
  let params = {
    password: password
  };
  return put(EndPoint.RESET_USER_PASSWORD, id, params, apiResponse, null);
}

export function deleteUser(id, history) {
  return deleteApi(
    EndPoint.DELETE_USERS,
    id,
    '',
    apiResponse,
    '/users',
    history
  );
}
export function addUserPayment(paymentData) {
  let params = paymentData;
  return post(EndPoint.ADD_USER_PAYMENT, params, apiResponse, null);
}

export function getUsersTable() {
  return get(EndPoint.USERS_TABLE, '', usersTable, null);
}

export function resetUser(id) {
  return clear(EndPoint.RESET_USER,{user_id: id});
}

