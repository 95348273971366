import React, {useState} from 'react'
import {
    Button,
    Modal,
    Backdrop,
    Fade,
    TextField,
    InputAdornment,
  } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import makeStyles from '../Styles';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';

const ResetPasswordModal = (props) => {
    const classes = makeStyles();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const {isLoading} = useSelector((state) => state.service)

    const onCloseModal = () => {
        setPassword('');
        setConfirmPassword('');
        setError(false);
        setShowPassword(false);
        props.modalClose();
    }

    const onResetPassword = () => {
        if(password !== confirmPassword){
            setError(true);
            return;
        }else if(isLoading){
            return;
        }
        setError(false);
        dispatch(actions.resetUserPassword(props.id, password));
        onCloseModal();
    }

    return (
        <Modal
          className={classes.modal}
          open={props.modalOpen}
          onClose={onCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.modalOpen}>
            <div className={classes.paper}>
            <h2 id="transition-modal-title">
                Type in the new password below
            </h2>
            {error && <p>Passwords don't match</p>}
            <div className={classes.resetPasswordInputContainer}>
                <TextField
                    required
                    label='Password'
                    value={password}
                    variant='outlined'
                    error={error}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(event) => setPassword(event.target.value)}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment onClick={() => setShowPassword(!showPassword)} position='start'>{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}</InputAdornment>
                        ),
                      }}
                />
                <br />
                <TextField
                    required
                    label='Confirm Password'
                    value={confirmPassword}
                    variant='outlined'
                    error={error}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                />
            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    onResetPassword();
                }}
            >
                Submit
            </Button>
            <Button
                variant="contained"
                color="default"
                style={{ marginLeft: 20 }}
                onClick={onCloseModal}
            >
                Cancel
            </Button>
            </div>
          </Fade>
        </Modal>
    )
}

export default ResetPasswordModal;