import { get } from './api';
import * as actionTypes from './constant/actionTypes';
import * as EndPoint from './constant/EndPoint';

export function countries(countries) {
  return {
    type: actionTypes.GET_COUNTRIES,
    countries: countries,
  };
}

export function states(states) {
  return {
    type: actionTypes.GET_STATES,
    states: states,
  };
}

export function cities(cities) {
  return {
    type: actionTypes.GET_CITIES,
    cities: cities,
  };
}

export function getCountries() {
  return get(EndPoint.GET_COUNTRIES, '', countries, null);
}
export function getStates(country_id) {
  let params = `country_id=${country_id}`;
  return get(EndPoint.GET_STATES, params, states, null);
}
export function getCities(state_id) {
  let params = `state_id=${state_id}`;
  return get(EndPoint.GET_CITIES, params, cities, null);
}
