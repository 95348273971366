import React from 'react';
import { Button } from '@material-ui/core';

const DeleteUserMedicine = (props) => {
  return (
    <div>
      <h2 id="transition-modal-title">
        Are you sure you want to delete medicine?
      </h2>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          props.dispatch();
          props.handelClose();
        }}
      >
        Delete
      </Button>
      <Button
        variant="contained"
        color="default"
        style={{ marginLeft: 20 }}
        onClick={() => props.handelClose()}
      >
        Cancel
      </Button>
    </div>
  );
};

export default DeleteUserMedicine;
