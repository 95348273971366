import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '90%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid grey',
    borderRadius: 15,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    width: '65%',
    margin: '2% 0 0 20%',
    outline: 'none',
  },
  resetPasswordInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '55%',
    marginBottom: '15px',
  },
  addUserRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  inline: {
    display: 'inline',
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  root: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: '#3b3b3b',
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: 'grey',
    },
    '& .MuiIconButton-root.Mui-checked.Mui-disabled': {
      color: 'blue',
    },
    '& .MuiSelect-select.Mui-disabled': {
      color: '#3b3b3b',
    },
  },
}));
