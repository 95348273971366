import * as actionTypes from '../actions/constant/actionTypes';

const initialState = {
  medicineItems: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.MEDICINES:
      const { medicineItems } = action;
      return { ...state, medicineItems: medicineItems};
    default:
      return state;
  }
}
