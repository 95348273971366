import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  TextField,
  Grid,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import makeStyles from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import SnackBar from '../../SnackBar/SnackBar';

const AddUserMedicines = (props) => {
  const classes = makeStyles();
  const { searchedMedicines } = useSelector((state) => state.userMedicines);
  const { mgRanges } = useSelector((state) => state.userMedicines);
  const { currentlyClickedUser } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [medicine_id, setMedicine_id] = useState('');
  const [medicine_name, setMedicine_name] = useState('');
  const [mgRange, setMgRange] = useState('');
  const [required_brand_only, setRequired_brand_only] = useState('');
  const [how_often_take, setHow_often_take] = useState('');
  const [otherHowOftenTake, setOtherHowOftenTake] = useState('');
  const [how_often_refill, setHow_often_refill] = useState('');
  const [otherHowOftenRefill, setOtherHowOftenRefill] = useState('');
  const [change, setChange] = useState(false);
  const [oftenTakeInput, setOftenTakeInput] = useState(false);
  const [oftenRefillInput, setOftenRefillInput] = useState(false);
  const [anyNote, setAnyNote] = React.useState('');    
  const addMedicine = (event) => {
    event.preventDefault();

    let medicineData = {
      user_id: props.user_id,
      medicines: [
        {
          medicine_id: medicine_id ? Number(medicine_id) : undefined,
          medicine_name: medicine_id ? undefined : medicine_name,
          mg_range: medicine_id ? undefined : mgRange,
          require_brand_only: JSON.parse(required_brand_only),
          how_often_take: how_often_take || otherHowOftenTake,
          how_often_refill: how_often_refill || otherHowOftenRefill,
          comments:anyNote
        },
      ],
    };
    dispatch(actions.addUserMedicines(medicineData));
    dispatch(actions.resetSearchedMedicineData());
    setMedicine_id('');
    setRequired_brand_only('');
    setHow_often_take('');
    setOtherHowOftenTake('');
    setHow_often_refill('');
    setOtherHowOftenRefill('');
    setChange(true);
    setOftenTakeInput(false);
    setOftenRefillInput(false);
    setTimeout(() => {
      props.handelClose();
    }, 500);
  };
  useEffect(() => {
    dispatch(actions.resetSearchedMedicineData());
  }, []);
  return (
    <div>
      <Typography
        variant="h6"
        align="left"
        color="primary"
        style={{ marginLeft: 10 }}
      >
        Add Medication
      </Typography>
      <Grid container direction="row">
        <Grid item sm={12}>
          <form className={classes.userDetailsRoot} onSubmit={addMedicine}>
            <div style={{ width: '85%', marginTop: 15 }}>
              <div style={{ display: 'flex' }}>
                <Autocomplete
                  freeSolo
                  style={{ width: 450 }}
                  key={change}
                  clearOnBlur={false}
                  disableClearable
                  autoComplete={true}
                  options={searchedMedicines}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, value) => {
                    dispatch(actions.getMgRanges(value.name));
                  }}
                  onInputChange={(event, value) => {
                    setMedicine_name(value);
                    if (value.length >= 1) {
                      dispatch(actions.getSearchedMedicines(value,currentlyClickedUser.id));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Medicine"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
                <Autocomplete
                  freeSolo
                  style={{ marginLeft: 20, width: 300 }}
                  clearOnBlur={false}
                  disableClearable
                  autoComplete={true}
                  options={mgRanges}
                  getOptionLabel={(option) => option.mg_range}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, value) => {
                    setMedicine_id(value.id);
                  }}
                  onInputChange={(event, value) => {
                    setMgRange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Dosage"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
                   <TextField 
                   id="outlined-basic" 
                   label="Notes" 
                   variant="outlined" 
                   onChange={(e)=>{
                     setAnyNote(e.target.value)
                    }}
                    style={{ width: '270px', marginLeft:"30px" }}
                    value={anyNote}
                    />
                   
              </div>
              <FormControl
                fullWidth
                required
                variant="outlined"
                style={{ marginLeft: 45 }}
                className={classes.formControl}
              >
                <FormLabel component="legend" style={{ textAlign: 'left' }}>
                  Requires Brand?
                </FormLabel>
                <RadioGroup
                  row
                  value={required_brand_only}
                  onChange={(event) =>
                    setRequired_brand_only(event.target.value)
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" required />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" required />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl
                fullWidth
                required
                variant="outlined"
                style={{ marginLeft: 45 }}
                className={classes.formControl}
              >
                <FormLabel component="legend" style={{ textAlign: 'left' }}>
                  How often do you take it per day?
                </FormLabel>
                <RadioGroup
                  row
                  value={how_often_take}
                  onChange={(event) => setHow_often_take(event.target.value)}
                >
                  <FormControlLabel
                    value="1x"
                    control={<Radio color="primary" required />}
                    onClick={() => setOftenTakeInput(false)}
                    label="1x"
                  />
                  <FormControlLabel
                    value="2x"
                    control={<Radio color="primary" required />}
                    onClick={() => setOftenTakeInput(false)}
                    label="2x"
                  />
                  <FormControlLabel
                    value="3x"
                    control={<Radio color="primary" required />}
                    onClick={() => setOftenTakeInput(false)}
                    label="3x"
                  />
                  <FormControlLabel
                    value="As Needed"
                    control={<Radio color="primary" required />}
                    onClick={() => setOftenTakeInput(false)}
                    label="As Needed"
                  />
                  <FormControlLabel
                    checked={oftenTakeInput}
                    control={<Radio color="primary" />}
                    onClick={() => {
                      setOftenTakeInput(true);
                      setHow_often_take('');
                    }}
                    label={
                      oftenTakeInput ? (
                        <TextField
                          required
                          label="Other"
                          placeholder="Enter How Often Take Medicine"
                          variant="outlined"
                          value={otherHowOftenTake}
                          onChange={(event) => {
                            setOtherHowOftenTake(event.target.value);
                          }}
                        />
                      ) : (
                        'Other'
                      )
                    }
                  />
                </RadioGroup>
              </FormControl>
              <FormControl
                fullWidth
                required
                variant="outlined"
                style={{ marginLeft: 45 }}
                className={classes.formControl}
              >
                <FormLabel component="legend" style={{ textAlign: 'left' }}>
                  How often do you refill it?
                </FormLabel>
                <RadioGroup
                  row
                  value={how_often_refill}
                  onChange={(event) => setHow_often_refill(event.target.value)}
                >
                  <FormControlLabel
                    value="Every Month"
                    control={<Radio color="primary" required />}
                    onClick={() => setOftenRefillInput(false)}
                    label="Every Month"
                  />
                  <FormControlLabel
                    value="Every Two Months"
                    control={<Radio color="primary" required />}
                    onClick={() => setOftenRefillInput(false)}
                    label="Every Two Months"
                  />
                  <FormControlLabel
                    value="Every Three Months"
                    control={<Radio color="primary" required />}
                    onClick={() => setOftenRefillInput(false)}
                    label="Every Three Months"
                  />
                  <FormControlLabel
                    value="As Needed"
                    control={<Radio color="primary" required />}
                    onClick={() => setOftenTakeInput(false)}
                    label="As Needed"
                  />
                  <FormControlLabel
                    checked={oftenRefillInput}
                    control={<Radio color="primary" />}
                    onClick={() => {
                      setOftenRefillInput(true);
                      setHow_often_refill('');
                    }}
                    label={
                      oftenRefillInput ? (
                        <TextField
                          required
                          label="Other"
                          placeholder="Enter When You Refill "
                          variant="outlined"
                          value={otherHowOftenRefill}
                          onChange={(event) => {
                            setOtherHowOftenRefill(event.target.value);
                          }}
                        />
                      ) : (
                        'Other'
                      )
                    }
                  />
                </RadioGroup>

              </FormControl>
            </div>
            <br />
            <Button variant="outlined" color="primary" type="submit">
              Add
            </Button>
          </form>
          <SnackBar />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddUserMedicines;
