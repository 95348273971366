import * as actionTypes from '../actions/constant/actionTypes';

const initialState = {
  countries: [],
  states: [],
  cities: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_COUNTRIES:
      const { countries } = action;
      return { ...state, countries: countries.data.countries };
    case actionTypes.GET_STATES:
      const { states } = action;
      return { ...state, states: states.data.states };
    case actionTypes.GET_CITIES:
      const { cities } = action;
      return { ...state, cities: cities.data.cities };
    default:
      return state;
  }
}
