import * as actionTypes from '../actions/constant/actionTypes';

const initialState = {
  isSidebarOpened: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.SIDEBAR:
            const {isSidebarOpened} = action;
            return {...state, isSidebarOpened};
    }
    return state;
}
