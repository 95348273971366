import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Button } from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';
import _ from 'lodash';
import makeStyles from './Styles';
import Table from '../../Table/Table';
import SnackBar from '../../SnackBar/SnackBar';
import AddUserMedicines from '../AddUserMedicines/AddUserMedicines';
import Modals from '../../Modal/Modals';
import DeleteUserMedicine from '../DeleteUserMedicine/DeleteUserMedicine';

const UserMedicines = (props) => {
  const dispatch = useDispatch();
  const classes = makeStyles();
  const { medicines } = useSelector((state) => state.userMedicines);
  const { usersTable } = useSelector((state) => state.users);
  let user;
  user = _.find(usersTable.data, function (data) {
    return data.id === props.id;
  });
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [currentDeletedMedicine, setCurrentDeletedMedicine] = useState({});
  
  const customComparator = (valueA, valueB) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  const handelClose = () => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 500);
    }
    if (modalOpen) {
      setTimeout(() => {
        setModalOpen(false);
      }, 500);
    }
  };
  const deleteDispatch = () => {
    dispatch(actions.deleteUserMedicines(deleteData));
  };
  useEffect(() => {
    if (!open && !modalOpen) {
      let user_id = user?.id;
      dispatch(actions.getUserMedicines(user_id));
    }
  }, [dispatch, open, modalOpen, user]);
  useEffect(() => {
    if (modalOpen) {
      setDeleteData({
        user_id: currentDeletedMedicine?.id,
        user_medicine_id: currentDeletedMedicine?.medicine_id,
      });
    }
  }, [currentDeletedMedicine, modalOpen]);

  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      tooltipValueGetter: (params) => params.data.name,
      cellStyle: { textAlign: 'left' },
      sort:"asc",
      comparator: customComparator
    },
    {
      headerName: 'Brand Only',
      field: 'require_brand_only',

      cellStyle: { textAlign: 'left', paddingLeft: '4%' },
    },
    {
      headerName: 'Takes Drug',
      field: 'how_often_take',

      cellStyle: { textAlign: 'left', paddingLeft: '4%' },
    },
    {
      headerName: 'Refill Prescription',
      field: 'how_often_refill',

      cellStyle: { textAlign: 'left', paddingLeft: '3%' },
    },
    {
      headerName: 'Note',
      field: 'comments',

      cellStyle: { textAlign: 'left', paddingLeft: '3%' },
    },
    {
      headerName: 'Delete Medicine',
      cellRendererFramework: (params) => (
        <DeleteForeverOutlined
          fontSize="small"
          style={{ color: 'red', cursor: 'pointer' }}
          onClick={() => {
            setModalOpen(true);
            setCurrentDeletedMedicine({
              id: params.data.id,
              medicine_id: params.data.medicine_id,
            });
          }}
        />
      ),
      cellStyle: { paddingRight: '8%' },
    },
  ];
  const rows = [];
  if (medicines !== undefined) {
    medicines.map((data) => {
      rows.push({
        name: `${data?.medicine?.name} ${data?.medicine?.mg_range}`,
        require_brand_only: !!data?.require_brand_only ? 'Yes' : 'No',
        how_often_take: data?.how_often_take,
        how_often_refill: data.how_often_refill,
        comments: data.comments,
        medicine_id: data.id,
        id: data.user_id,
      });
    });
  }
  const defaultColDef = { resizable: true }
  let modalData;
  if (open) {
    modalData = (
      <AddUserMedicines user_id={user.id} handelClose={handelClose} />
    );
  }
  if (modalOpen) {
    modalData = (
      <DeleteUserMedicine dispatch={deleteDispatch} handelClose={handelClose} />
    );
  }

  return (
    <div className={classes.paper}>
      <Modals
        open={open ? open : modalOpen}
        handelClose={handelClose}
        modalData={modalData}
      />
      <div
        style={{
          width: '100%',
        }}
      >
        <h2 style={{ textAlign: 'left' }}>
          {user?.name}'s medication list{' '}
          <Button
            style={{ float: 'right' }}
            color="primary"
            variant="outlined"
            onClick={() => setOpen(true)}
          >
            Add Medication
          </Button>
        </h2>

        <Table rows={rows} columns={columns} rowStyle="cursor" defaultColDef={defaultColDef} />
        <SnackBar />
      </div>
    </div>
  );
};
export default UserMedicines;
