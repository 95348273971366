import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { TextField, Grid, Typography, Button } from '@material-ui/core';
import makeStyles from './styles';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';

//Redux
import { useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import SnackBar from '../SnackBar/SnackBar';

const CreateNewLogin = (props) => {
  const classes = makeStyles();
  const dispatch = useDispatch();
  //States
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const addUser = (event) => {
    event.preventDefault();
    let userData = {
      user: {
        first_name: firstName?.toString(),
        last_name: lastName?.toString(),
        email: email?.toString(),
        password: password?.toString(),
      },
      role: {
        role_id: 2,
      },
    };
    dispatch(actions.addNewUser(userData));
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
  };
  return (
    <div>
      <Header history={props.history} />
      <Sidebar />
      <div className={classes.paper}>
        <Typography
          variant="h6"
          align="left"
          color="primary"
          style={{ marginLeft: 10 }}
        >
          Create New Login
        </Typography>
        <Grid container direction="row">
          <Grid item sm={12}>
            <form className={classes.createNewClientRoot} onSubmit={addUser}>
              <div style={{ display: 'flex' }}>
                <TextField
                  required
                  label="First Name"
                  value={firstName}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => setFirstName(event.target.value)}
                />
                <TextField
                  required
                  label="Last Name"
                  value={lastName}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => setLastName(event.target.value)}
                />
              </div>
              <TextField
                autoComplete="off"
                required
                label="Email"
                value={email}
                variant="outlined"
                type="email"
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                autoComplete="new-password"
                required
                label="Password"
                value={password}
                variant="outlined"
                type="password"
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
              />
              <br />
              <Button variant="outlined" color="primary" type="submit">
                Add
              </Button>
            </form>
            <SnackBar />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CreateNewLogin;
