import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextField,
  Grid,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import "./AddUserForm.module.css";
import makeStyles from "./Styles";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import SnackBar from "../SnackBar/SnackBar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";

const AddUserForm = (props) => {
  const classes = makeStyles();
  const { states } = useSelector((state) => state.dataServices);
  const dispatch = useDispatch();
  //States
  const [selectedState, setSelectedState] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [legalFirstName, setLegalFirstName] = useState("");
  const [middleInitial, setMiddleInitial] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState(null);
  const [medicareNumber, setMedicareNumber] = useState("");
  const [aDate, setADate] = useState("");
  const [bDate, setBDate] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneType, setPhoneType] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyContactRelation, setEmergencyContactRelation] = useState("");
  const [otherEmergencyContactRelation, setOtherEmergencyContactRelation] =
    useState("");
  const [socialBenefits, setSocialBenefits] = useState("");
  const [payBenefits, setPayBenefits] = useState("");
  const [checkMyMedications, setCheckMyMedications] = useState("");
  const [relationInput, setRelationInput] = useState(false);

  // Effects
  useEffect(() => {
    let country_id = 95;
    dispatch(actions.getStates(country_id));
  }, []);

  const addUser = (event) => {
    event.preventDefault();
    let userData = {
      user: {
        first_name: firstName?.toString(),
        legal_first_name: legalFirstName?.toString(),
        middle_initial: middleInitial?.toString(),
        last_name: lastName?.toString(),
        dob: dob ? dob : null,
        medicare_number: medicareNumber?.toString(),
        a_date: aDate?.toString(),
        b_date: bDate?.toString(),
        email: email?.toString(),
        password: password?.toString(),
        phone: phone,
        phone_no_type: Number(phoneType),
        address: address?.toString(),
        city_name: city,
        state_id: state,
        country_id: 95,
        zip_code: zipCode?.toString(),
        phone_emergency: emergencyContact,
        phone_emergency_contact_name: emergencyContactName?.toString(),
        phone_emergency_contact_relationship:
          emergencyContactRelation?.toString(),
        contact_relationship_others: otherEmergencyContactRelation?.toString(),
        social_benefits: JSON.parse(socialBenefits),
        social_benefits_paid_automatically: JSON.parse(payBenefits),
        check_my_medications: JSON.parse(checkMyMedications),
      },
      role: {
        role_id: 1,
      },
    };
    dispatch(actions.addNewUser(userData));
    setFirstName("");
    setLegalFirstName("");
    setMiddleInitial("");
    setLastName("");
    setDob(null);
    setMedicareNumber("");
    setADate("");
    setBDate("");
    setEmail("");
    setPassword("");
    setPhone("");
    setPhoneType("");
    setAddress("");
    setState("");
    setSelectedState("");
    setCity("");
    setZipCode("");
    setEmergencyContact("");
    setEmergencyContactName("");
    setEmergencyContactRelation("");
    setOtherEmergencyContactRelation("");
    setSocialBenefits("");
    setPayBenefits("");
    setCheckMyMedications("");
    setRelationInput(false);
  };
  const createLabel = (s) => {
    return s.name;
  };
  const selectionChanged = (e, newSelectedState) => {
    if (newSelectedState && newSelectedState.id) {
      setSelectedState(newSelectedState);
      setState(newSelectedState.id);
    }
  };

  const onDateChange = (event) => {
    setDob(moment(event.target.value).utc().format("YYYY-MM-DD"));
  };
  return (
    <div>
      <Header history={props.history} />
      <Sidebar />
      <div className={classes.paper}>
        <Typography
          variant="h6"
          align="left"
          color="primary"
          style={{ marginLeft: 10 }}
        >
          Add Client
        </Typography>
        <Grid container direction="row">
          <Grid item sm={12}>
            <form className={classes.addUserRoot} onSubmit={addUser}>
              <div style={{ display: "flex" }}>
                <TextField
                  required
                  label="First Name"
                  value={firstName}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => setFirstName(event.target.value)}
                />
                <TextField
                  required
                  label="Legal First Name"
                  value={legalFirstName}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => setLegalFirstName(event.target.value)}
                />
                <TextField
                  required
                  label="Middle Name"
                  value={middleInitial}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => setMiddleInitial(event.target.value)}
                />
                <TextField
                  required
                  label="Last Name"
                  value={lastName}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => setLastName(event.target.value)}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  required
                  id="date"
                  type="date"
                  label="Date Of Birth"
                  value={moment(dob).utc().format("YYYY-MM-DD")}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onDateChange}
                />
                <TextField
                  label="Medicare Number (If Any)"
                  value={medicareNumber}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => setMedicareNumber(event.target.value)}
                />
                <TextField
                  type="date"
                  label="A Date (If Any)"
                  value={aDate}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => setADate(event.target.value)}
                />
                <TextField
                  type="date"
                  label="B Date (If Any)"
                  value={bDate}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => setBDate(event.target.value)}
                />
              </div>
              <TextField
                autoComplete="off"
                required
                label="Email"
                value={email}
                variant="outlined"
                type="email"
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                autoComplete="new-password"
                required
                label="Password"
                value={password}
                variant="outlined"
                type="password"
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
              />
              <div style={{ display: "flex" }}>
                <FormControl>
                  <TextField
                    required
                    id="phone-required"
                    label="Phone"
                    value={phone}
                    variant="outlined"
                    type="tel"
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </FormControl>
                <FormControl
                  required
                  variant="outlined"
                  style={{ marginLeft: 45 }}
                  className={classes.formControl}
                >
                  <FormLabel component="legend" style={{ textAlign: "left" }}>
                    This is my
                  </FormLabel>
                  <RadioGroup
                    row
                    value={phoneType}
                    onChange={(event) => setPhoneType(event.target.value)}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" required />}
                      label="Home Number"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary" required />}
                      label="Cell Number"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <TextField
                label="Address"
                variant="outlined"
                required
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
              <div style={{ display: "flex" }}>
                <TextField
                  label="City Name"
                  variant="outlined"
                  required
                  style={{ width: "25%" }}
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                />
                <FormControl variant="outlined">
                  <Autocomplete
                    id="state-select"
                    style={{ width: 200, marginRight: "10px" }}
                    options={states}
                    onChange={selectionChanged}
                    value={selectedState ? selectedState : null}
                    getOptionLabel={(option) => createLabel(option)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="State"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    required
                    label="ZipCode"
                    value={zipCode}
                    variant="outlined"
                    fullWidth
                    type="number"
                    onChange={(event) => setZipCode(event.target.value)}
                  />
                </FormControl>
              </div>
              <TextField
                required
                label="Emergency Contact Name"
                value={emergencyContactName}
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  setEmergencyContactName(event.target.value)
                }
              />
              <TextField
                required
                label="Emergency Contact Number"
                value={emergencyContact}
                variant="outlined"
                fullWidth
                type="tel"
                onChange={(event) => setEmergencyContact(event.target.value)}
              />
              <div style={{ width: "65%", marginTop: 25, paddingLeft: 10 }}>
                <FormControl
                  required
                  style={{ display: "block", marginBottom: 15 }}
                >
                  <FormLabel component="legend" style={{ textAlign: "left" }}>
                    Relationship to you
                  </FormLabel>
                  <RadioGroup
                    row
                    value={emergencyContactRelation}
                    onChange={(event) =>
                      setEmergencyContactRelation(event.target.value)
                    }
                  >
                    <FormControlLabel
                      value="spouse"
                      control={<Radio color="primary" required />}
                      label="Spouse"
                      onClick={() => setRelationInput(false)}
                    />
                    <FormControlLabel
                      value="son"
                      control={<Radio color="primary" required />}
                      label="Son"
                      onClick={() => setRelationInput(false)}
                    />
                    <FormControlLabel
                      value="daughter"
                      control={<Radio color="primary" required />}
                      label="Daughter"
                      onClick={() => setRelationInput(false)}
                    />
                    <FormControlLabel
                      checked={relationInput}
                      control={<Radio color="primary" />}
                      onClick={() => {
                        setRelationInput(true);
                        setEmergencyContactRelation("other");
                      }}
                      label={
                        relationInput ? (
                          <TextField
                            required
                            label="Other"
                            placeholder="Enter Relation"
                            variant="outlined"
                            value={otherEmergencyContactRelation}
                            onChange={(event) => {
                              setOtherEmergencyContactRelation(
                                event.target.value
                              );
                            }}
                          />
                        ) : (
                          "Other"
                        )
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl style={{ display: "block", marginBottom: 15 }}>
                  <FormLabel component="legend" style={{ textAlign: "left" }}>
                    Are you currently taking your Social Security benefits?
                  </FormLabel>
                  <RadioGroup
                    name="socialbenifetis"
                    row
                    value={socialBenefits}
                    onChange={(event) => setSocialBenefits(event.target.value)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" required />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" required />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl style={{ display: "block", marginBottom: 15 }}>
                  <FormLabel component="legend" style={{ textAlign: "left" }}>
                    We love evaluating your Part D Plan! Shall we do it this
                    year?
                  </FormLabel>
                  <RadioGroup
                    name="checkmymedications"
                    row
                    value={checkMyMedications}
                    onChange={(event) =>
                      setCheckMyMedications(event.target.value)
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" required />}
                      label="Yes! I want peace of mind and possibly financial savings! Let's get started"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" required />}
                      label="No, I'm just hoping."
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl style={{ display: "block", marginBottom: 15 }}>
                  <FormLabel component="legend" style={{ textAlign: "left" }}>
                    If yes, do you want your Part D premiums paid automatically
                    from your benefits?
                  </FormLabel>
                  <RadioGroup
                    name="paysocialbenifetis"
                    row
                    value={payBenefits}
                    onChange={(event) => setPayBenefits(event.target.value)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" required />}
                      label="Yes, Simpler that way"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" required />}
                      label="No, I prefer to pay it myself"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <br />
              <Button variant="outlined" color="primary" type="submit">
                Add
              </Button>
            </form>
            <SnackBar />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AddUserForm;
