import * as actionTypes from './constant/actionTypes';

//Payloads

export function toggleSidebar(isSidebarOpened) {
  return {
    type: actionTypes.SIDEBAR,
    isSidebarOpened: isSidebarOpened,
  };
}
