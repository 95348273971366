import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid grey',
    borderRadius: 15,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    width: '65%',
    margin: '7% 0 0 10%',
  },
  createNewClientRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));
