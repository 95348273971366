import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid grey',
    borderRadius: 15,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    width: '65%',
    margin: '7% 0 0 10%',
    outline: 'none',
  },
  userDetailsRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  inline: {
    display: 'inline',
  },
  root: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: '#3b3b3b',
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: 'grey',
    },
    '& .MuiIconButton-root.Mui-checked.Mui-disabled': {
      color: 'blue',
    },
    '& .MuiSelect-select.Mui-disabled': {
      color: '#3b3b3b',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
