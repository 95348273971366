import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Typography,
  Button,
  InputAdornment,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import makeStyles from '../styles';
import * as actions from '../../../redux/actions';

const AddPayment = (props) => {
  const classes = makeStyles();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState('');
  const [checqueId, setChecqueId] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const addPaymentHandler = (event) => {
    event.preventDefault();
    let paymentData = {
      user_id: props.user_id,
      amount: amount.toString(),
      payment_date: moment(selectedDate).format('YYYY-MM-DD hh:mm:ss'),
      payment_via_id: checqueId.toString(),
      payment_status: 1,
      payment_via: 1,
    };
    dispatch(actions.addUserPayment(paymentData));
    props.modalClose();
  };

  return (
    <div className={classes.paper}>
      <Typography
        variant='h6'
        align='left'
        color='primary'
        style={{ marginLeft: 10 }}>
        Add User's Payment
      </Typography>
      <form className={classes.userDetailsRoot} onSubmit={addPaymentHandler}>
        <div style={{ display: 'flex' }}>
          <TextField
            required
            label='Amount'
            value={amount}
            variant='outlined'
            type='number'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            onChange={(event) => setAmount(event.target.value)}
          />
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant='inline'
              inputVariant='outlined'
              format='MM-DD-YYYY'
              margin='normal'
              label='Payment Date '
              helperText='Please add MM-DD-YYYY'
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <TextField
          label='Check No'
          value={checqueId}
          variant='outlined'
          type='number'
          helperText='Optional* Add Check No'
          onChange={(event) => setChecqueId(event.target.value)}
        />

        <br />
        <Button
          variant='contained'
          color='primary'
          type='submit'
          style={{ marginLeft: 10 }}>
          Add
        </Button>
        <Button
          variant='contained'
          color='default'
          style={{ marginLeft: 10 }}
          onClick={props.modalClose}>
          Cancel
        </Button>
      </form>
    </div>
  );
};

export default AddPayment;
