import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { setCurrentlyClickedUser } from "../../redux/actions/usersAction";
import { useDispatch } from "react-redux";
const Table = (props) => {
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = React.useState(null);
  const users = localStorage.getItem("recentUsers");
  if (!users) {
    localStorage.setItem("recentUsers", JSON.stringify([]));
  }
  const { rows, columns } = props;
  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    setGridApi(params.api);
  };

  const onCellClicked = (params) => {
    if(params.colDef.headerName === 'Action'){
      return;
    }
    dispatch(setCurrentlyClickedUser(params.data));
    if (params.colDef.headerName !== "What Changed" && props.redirect) {
      props.history.push({
        pathname: props.link,
        state: { id: params.data.id },
      });
    }
    const recentUsers = JSON.parse(localStorage.getItem("recentUsers"));
    if (!Array.isArray(recentUsers)) {
      localStorage.setItem("recentUsers", JSON.stringify([]));
    }
    if (recentUsers.length < 1) {
      params.data.dateTime = moment().valueOf();
      recentUsers.push(params.data);
      localStorage.setItem("recentUsers", JSON.stringify(recentUsers));
    } else {
      const found = recentUsers.findIndex((user) => user.id === params.data.id);

      if (found === -1) {
        params.data.dateTime = moment().valueOf();
        if (recentUsers.length > 2) {
          recentUsers.shift();
        }
        recentUsers.push(params.data);
      } else{
        recentUsers[found].dateTime = moment().valueOf();
      }
      localStorage.setItem("recentUsers", JSON.stringify(recentUsers));
    }
  };
  const getRowStyle = (params) => {
    if (params.data.dateTime) {
      if (
        params.node.rowIndex === 0 ||
        params.node.rowIndex === 1 ||
        params.node.rowIndex === 2
      )
        return { background: "rgb(144,200,144)" };
    }
  };

  
useEffect(()=>{
  if (!gridApi) {
      return;
    } else {
      const nameInstance = gridApi.getFilterInstance("name");
      if (props.searchText) {
        nameInstance.setModel({
          type: "contains",
          filter: props.searchText,
        });
      }else{
        nameInstance.setModel(null)
      }
      gridApi.onFilterChanged();
    }

},[props.searchText])
  return (
    <div
      className="ag-theme-alpine"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <AgGridReact
        defaultColDef={props.defaultColDef || null}
        domLayout="autoHeight"
        tooltipShowDelay={0}
        enableBrowserTooltips={true}
        onGridReady={onGridReady}
        columnDefs={columns}
        rowData={rows}
        rowSelection="single"
        pagination={true}
        paginationPageSize={500}
        viewportRowModelPageSize={1}
        rowStyle={{ cursor: props.rowStyle || "pointer" }}
        onCellClicked={onCellClicked}
        getRowStyle={getRowStyle}
        quickFilterText={props.filterText || null}
        animateRows={true}
      />
    </div>
  );
};

export default withRouter(Table);
