import React, { useEffect, useRef } from 'react';
import { TextField, Button, FormControl } from '@material-ui/core';
import makeStyles from '../styles';

const Form = (props) => {
  const classes = makeStyles();
  const inputFocus = useRef();

  useEffect(() => {
    inputFocus.current.focus();
  }, []);
  return (
    <form className={classes.addUserRoot} onSubmit={props.submit}>
      <TextField
        required
        name="name"
        inputRef={inputFocus}
        label="Name"
        value={props.name}
        variant="outlined"
        fullWidth
        onChange={props.handelChange}
      />
      <TextField
        name="description"
        label="Description"
        value={props.description}
        variant="outlined"
        type="text"
        fullWidth
        multiline
        rows={4}
        onChange={props.handelChange}
      />
      <TextField
        required
        name="genericName"
        label="Generic name"
        value={props.genericName}
        variant="outlined"
        type="text"
        fullWidth
        onChange={props.handelChange}
      />

      <div style={{ display: 'flex' }}>
        <FormControl>
          <TextField
            required
            name="mgRange"
            label="Dosage"
            value={props.mgRange}
            variant="outlined"
            fullWidth
            type="text"
            onChange={props.handelChange}
          />
        </FormControl>
      </div>
      <br />
      <Button variant="outlined" color="primary" type="submit">
        Save
      </Button>
    </form>
  );
};

export default Form;
