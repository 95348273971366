import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  TextField,
  Grid,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import {
  CreateOutlined,
  DeleteForeverOutlined,
  MonetizationOnOutlined,
} from "@material-ui/icons";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import "../AddUserForm/AddUserForm.module.css";
import makeStyles from "./Styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";
import AddPayment from "../UserDetails/AddPayment/AddPayment";
import { withRouter } from "react-router-dom";
import SnackBar from "../SnackBar/SnackBar";
import ResetPasswordModal from "./ResetPasswordModal";

const EditUser = (props) => {
  const classes = makeStyles();
  const { usersTable } = useSelector((state) => state.users);
  const { states } = useSelector((state) => state.dataServices);
  const dispatch = useDispatch();
  let user;
  user = _.find(usersTable.data, function (data) {
    return data.id === props.id;
  });
  //States

  const inputFocus = useRef();
  const [firstName, setFirstName] = useState(user?.first_name);
  const [legalFirstName, setLegalFirstName] = useState(user?.legal_first_name);
  const [middleInitial, setMiddleInitial] = useState(user?.middle_initial);
  const [lastName, setLastName] = useState(user?.last_name);
  const [dob, setDob] = useState(user?.dob ? user?.dob : null);
  const [medicareNumber, setMedicareNumber] = useState(user?.medicare_number);
  const [aDate, setADate] = useState(user?.a_date);
  const [bDate, setBDate] = useState(user?.b_date);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phone);
  const [phoneType, setPhoneType] = useState(user?.phone_no_type);
  const [address, setAddress] = useState(user?.address);
  const [city, setCity] = useState(user?.city_name);
  const [zipCode, setZipCode] = useState(user?.zip_code);
  const [countryState, setCountryState] = useState(user?.state_id);
  const [pharmacyAddress, setPharmacyAddress] = useState(
    user?.pharmacy_address
  );
  const [selectedState, setSelectedState] = useState(null);
  const [emergencyContact, setEmergencyContact] = useState(
    user?.phone_emergency
  );
  const [emergencyContactName, setEmergencyContactName] = useState(
    user?.phone_emergency_contact_name
  );
  const [emergencyContactRelation, setEmergencyContactRelation] = useState(
    user?.phone_emergency_contact_relationship?.toLowerCase()
  );
  const [otherEmergencyContactRelation, setOtherEmergencyContactRelation] =
    useState(user?.contact_relationship_others?.toLowerCase());
  const [socialBenefits, setSocialBenefits] = useState(!!user?.social_benefits);
  const [socialBenefitsChangedLastYear, setSocialBenefitsChangedLastYear] =
    useState(!!user?.social_benefits_changed_last_year);
  const [payBenefits, setPayBenefits] = useState(
    !!user?.social_benefits_paid_automatically
  );
  const [checkMyMedications, setCheckMyMedications] = useState(
    !!user?.check_my_medications
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [editAble, setEditAble] = useState(false);
  const [addPayment, setAddPayment] = useState(false);
  const [relationInput, setRelationInput] = useState(
    user?.phone_emergency_contact_relationship?.toLowerCase() !== "spouse" &&
      user?.phone_emergency_contact_relationship?.toLowerCase() !== "son" &&
      user?.phone_emergency_contact_relationship?.toLowerCase() !== "daughter"
      ? true
      : false
  );

  // Effects
  useEffect(() => {
    let country_id = 95;
    dispatch(actions.getStates(country_id));
  }, []);

  useEffect(() => {
    dispatch(actions.getCities(countryState));
  }, [countryState]);

  useEffect(() => {
    if (editAble) {
      inputFocus.current.focus();
    }
  }, [editAble]);

  useEffect(() => {
    const selectedState = states.find((sd) => sd.id === user?.state_id);
    setSelectedState(selectedState);
  }, [states, user]);

  const editUser = (event) => {
    event.preventDefault();
    let id = user?.id;
    let userData = {
      first_name: firstName?.toString(),
      legal_first_name: legalFirstName?.toString(),
      middle_initial: middleInitial?.toString(),
      last_name: lastName?.toString(),
      dob: dob ? dob : null,
      medicare_number: medicareNumber?.toString(),
      a_date: aDate?.toString(),
      b_date: bDate?.toString(),
      email: email?.toString(),
      phone: phone,
      phone_no_type: Number(phoneType),
      address: address?.toString(),
      city_name: city,
      state_id: countryState,
      zip_code: Number(zipCode),
      country_id: 95,
      phone_emergency: emergencyContact,
      phone_emergency_contact_name: emergencyContactName?.toString(),
      phone_emergency_contact_relationship:
        emergencyContactRelation?.toString(),
      pharmacy_address: pharmacyAddress?.toString(),
      social_benefits: JSON.parse(socialBenefits),
      social_benefits_changed_last_year: JSON.parse(
        socialBenefitsChangedLastYear
      ),
      social_benefits_paid_automatically: JSON.parse(payBenefits),
      check_my_medications: JSON.parse(checkMyMedications),
    };
    dispatch(actions.editUser(id, userData, props.history));
  };
  const modalClose = () => {
    setEditAble(false);
    setTimeout(() => {
      setAddPayment(false);
    }, 500);
    setModalOpen(false);
  };
  const makeEditAble = () => {
    setEditAble(!editAble);
  };
  const createLabel = (s) => {
    return s.name;
  };
  const selectionChanged = async (e, newSelectedState) => {
    if (newSelectedState && newSelectedState.id) {
      setCountryState(newSelectedState.id);
      setSelectedState(newSelectedState);
    }
  };
  let editButtonStyle = "outlined";
  if (editAble) {
    editButtonStyle = "contained";
  }

  const onDateChange = (event) => {
    setDob(event.target.value);
  };
  console.log(moment(dob).zone());

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <ResetPasswordModal
          id={user?.id}
          modalOpen={passwordModalOpen}
          modalClose={() => setPasswordModalOpen(false)}
        />
        <Modal
          className={classes.modal}
          open={modalOpen}
          onClose={modalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modalOpen}>
            {addPayment ? (
              <AddPayment user_id={user.id} modalClose={modalClose} />
            ) : (
              <div className={classes.paper}>
                <h2 id="transition-modal-title">
                  Are you sure you want to delete user?
                </h2>
                <p>This action cannot be undone.</p>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    dispatch(actions.deleteUser(user.id, props.history));
                    setModalOpen(false);
                  }}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  style={{ marginLeft: 20 }}
                  onClick={modalClose}
                >
                  Cancel
                </Button>
              </div>
            )}
          </Fade>
        </Modal>
        <Typography
          variant="h6"
          align="left"
          color="primary"
          style={{ marginLeft: 10 }}
        >
          User Details
          <Button
            variant={editButtonStyle}
            color="primary"
            style={{ marginLeft: 20 }}
            onClick={makeEditAble}
          >
            Edit
            <CreateOutlined fontSize="small" />
          </Button>{" "}
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginLeft: 10 }}
            onClick={() => setModalOpen(true)}
          >
            Delete
            <DeleteForeverOutlined fontSize="small" />
          </Button>
          <Button
            variant="outlined"
            style={{ marginLeft: 10, color: "green", borderColor: "green" }}
            onClick={() => {
              setModalOpen(true);
              setAddPayment(true);
            }}
          >
            Add Payment
            <MonetizationOnOutlined fontSize="small" />
          </Button>
          <Button
            variant="outlined"
            style={{ marginLeft: 10, color: "purple", borderColor: "purple" }}
            onClick={() => {
              setPasswordModalOpen(true);
            }}
          >
            Reset Password
            <RotateLeftIcon fontSize="small" />
          </Button>
        </Typography>
        <Grid container direction="row" style={{ marginTop: "2%" }}>
          <Grid item sm={12}>
            <form className={classes.addUserRoot} onSubmit={editUser}>
              <div style={{ display: "flex" }}>
                <TextField
                  inputRef={inputFocus}
                  label="First Name"
                  value={firstName}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: !editAble,
                  }}
                  onChange={(event) => setFirstName(event.target.value)}
                />
                <TextField
                  label="Legal First Name"
                  value={legalFirstName}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: !editAble,
                  }}
                  onChange={(event) => setLegalFirstName(event.target.value)}
                />
                <TextField
                  label="Middle Name"
                  value={middleInitial}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: !editAble,
                  }}
                  onChange={(event) => setMiddleInitial(event.target.value)}
                />
                <TextField
                  label="Last Name"
                  value={lastName}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: !editAble,
                  }}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  required
                  id="date"
                  type="date"
                  label="Date Of Birth"
                  value={dob}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: !editAble,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onDateChange}
                />
                <TextField
                  label="Medicare Number (If Any)"
                  value={medicareNumber}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: !editAble,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => setMedicareNumber(event.target.value)}
                />
                <TextField
                  type="date"
                  label="A Date (If Any)"
                  value={aDate}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: !editAble,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => setADate(event.target.value)}
                />
                <TextField
                  type="date"
                  label="B Date (If Any)"
                  value={bDate}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: !editAble,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => setBDate(event.target.value)}
                />
              </div>
              <TextField
                required
                label="Email"
                value={email}
                variant="outlined"
                type="email"
                fullWidth
                InputProps={{
                  readOnly: !editAble,
                }}
                onChange={(event) => setEmail(event.target.value)}
              />
              <div style={{ display: "flex" }}>
                <FormControl>
                  <TextField
                    id="phone-required"
                    label="Phone"
                    value={phone}
                    variant="outlined"
                    InputProps={{
                      readOnly: !editAble,
                    }}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  style={{ marginLeft: 45 }}
                  className={classes.formControl}
                >
                  <FormLabel component="legend" style={{ textAlign: "left" }}>
                    This is my
                  </FormLabel>
                  <RadioGroup
                    row
                    value={phoneType?.toString()}
                    onChange={(event) => setPhoneType(event.target.value)}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="Home Number"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="Cell Number"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <TextField
                label="Address"
                value={address}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: !editAble,
                }}
                onChange={(event) => setAddress(event.target.value)}
              />
              <div style={{ display: "flex" }}>
                <TextField
                  label="City Name"
                  value={city}
                  variant="outlined"
                  style={{ width: "35%" }}
                  InputProps={{
                    readOnly: !editAble,
                  }}
                  onChange={(event) => setCity(event.target.value)}
                />

                <FormControl variant="outlined">
                  <Autocomplete
                    disabled={!editAble}
                    id="state-select"
                    style={{ width: 200, marginRight: "10px" }}
                    options={states}
                    onChange={selectionChanged}
                    value={selectedState ? selectedState : null}
                    getOptionLabel={(option) => createLabel(option)}
                    renderInput={(params) => (
                      <TextField {...params} label="State" variant="outlined" />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    label="ZipCode"
                    InputProps={{
                      readOnly: !editAble,
                    }}
                    value={zipCode}
                    variant="outlined"
                    fullWidth
                    type="number"
                    onChange={(event) => setZipCode(event.target.value)}
                  />
                </FormControl>
              </div>

              <TextField
                label="Emergency Contact Name"
                value={emergencyContactName}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: !editAble,
                }}
                onChange={(event) =>
                  setEmergencyContactName(event.target.value)
                }
              />
              <TextField
                label="Emergency Contact Number"
                value={emergencyContact}
                variant="outlined"
                fullWidth
                type="tel"
                InputProps={{
                  readOnly: !editAble,
                }}
                onChange={(event) => setEmergencyContact(event.target.value)}
              />
              <TextField
                label="Pharmacy Address"
                value={pharmacyAddress}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: !editAble,
                }}
                onChange={(event) => setPharmacyAddress(event.target.value)}
              />
              <div style={{ width: "65%", marginTop: 25, paddingLeft: 10 }}>
                <FormControl style={{ display: "block", marginBottom: 15 }}>
                  <FormLabel component="legend" style={{ textAlign: "left" }}>
                    Relationship to you
                  </FormLabel>
                  <RadioGroup
                    row
                    value={emergencyContactRelation}
                    onChange={(event) =>
                      setEmergencyContactRelation(event.target.value)
                    }
                  >
                    <FormControlLabel
                      value="spouse"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="Spouse"
                      onClick={() =>
                        editAble ? setRelationInput(false) : null
                      }
                    />
                    <FormControlLabel
                      value="son"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="Son"
                      onClick={() =>
                        editAble ? setRelationInput(false) : null
                      }
                    />
                    <FormControlLabel
                      value="daughter"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="Daughter"
                      onClick={() =>
                        editAble ? setRelationInput(false) : null
                      }
                    />
                    <FormControlLabel
                      checked={relationInput}
                      control={<Radio color="primary" disabled={!editAble} />}
                      onClick={() => {
                        if (editAble) {
                          setRelationInput(true);
                          setEmergencyContactRelation("other");
                        }
                      }}
                      label={
                        relationInput ? (
                          <TextField
                            label="Other"
                            placeholder="Enter relation"
                            variant="outlined"
                            value={otherEmergencyContactRelation}
                            onChange={(event) => {
                              setOtherEmergencyContactRelation(
                                event.target.value
                              );
                            }}
                            InputProps={{
                              readOnly: !editAble,
                            }}
                          />
                        ) : (
                          "Other"
                        )
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl style={{ display: "block", marginBottom: 15 }}>
                  <FormLabel component="legend" style={{ textAlign: "left" }}>
                    Are you currently taking your Social Security benefits?
                  </FormLabel>
                  <RadioGroup
                    name="socialbenifetis"
                    row
                    value={socialBenefits?.toString()}
                    onChange={(event) => setSocialBenefits(event.target.value)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl style={{ display: "block", marginBottom: 15 }}>
                  <FormLabel component="legend" style={{ textAlign: "left" }}>
                    Has this changed in the past year?
                  </FormLabel>
                  <RadioGroup
                    name="socialbenifetis"
                    row
                    value={socialBenefitsChangedLastYear?.toString()}
                    onChange={(event) =>
                      setSocialBenefitsChangedLastYear(event.target.value)
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl style={{ display: "block", marginBottom: 15 }}>
                  <FormLabel component="legend" style={{ textAlign: "left" }}>
                    We love evaluating your Part D Plan! Shall we do it this
                    year?
                  </FormLabel>
                  <RadioGroup
                    name="checkmymedications"
                    row
                    value={checkMyMedications?.toString()}
                    onChange={(event) =>
                      setCheckMyMedications(event.target.value)
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="Yes! I want peace of mind and possibly financial savings! Let's get started"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="No, I'm just hoping."
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl style={{ display: "block", marginBottom: 15 }}>
                  <FormLabel component="legend" style={{ textAlign: "left" }}>
                    If yes, do you want your Part D premiums paid automatically
                    from your benefits?
                  </FormLabel>
                  <RadioGroup
                    name="paysocialbenifetis"
                    row
                    value={payBenefits?.toString()}
                    onChange={(event) => setPayBenefits(event.target.value)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="Yes, Simpler that way"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" disabled={!editAble} />}
                      label="No, I prefer to pay it myself"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <br />
              {editAble ? (
                <Button variant="outlined" color="primary" type="submit">
                  Save
                </Button>
              ) : null}
            </form>
            <SnackBar />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(EditUser);
