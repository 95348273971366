import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';

//Redux
import { useDispatch } from 'react-redux';
import * as actions from '../../../redux/actions';
import SnackBar from '../../SnackBar/SnackBar';
import Form from '../Form/Form';

const AddMedicineForm = (props) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [genericName, setGenericName] = useState('');
  const [mgRange, setMgRange] = useState('');

  const handelChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'description':
        setDescription(value);
        break;
      case 'genericName':
        setGenericName(value);
        break;
      case 'mgRange':
        setMgRange(value);
      default:
        return;
    }
  };

  const addMedicine = (event) => {
    event.preventDefault();
    let medicineData = {
      name: name?.toString(),
      description: description?.toString(),
      generic_name: genericName?.toString(),
      mg_range: mgRange?.toString(),
      category: 'Tab',
    };
    dispatch(actions.addMedicine(medicineData));
    setName('');
    setDescription('');
    setGenericName('');
    setMgRange('');
    props.handelClose();
  };

  return (
    <div>
      <div>
        <Typography
          variant="h6"
          align="left"
          color="primary"
          style={{ marginLeft: 10 }}
        >
          Add Medicine
        </Typography>
        <Grid container direction="row">
          <Grid item sm={12}>
            <Form
              name={name}
              description={description}
              genericName={genericName}
              mgRange={mgRange}
              editAble={true}
              handelChange={handelChange}
              submit={addMedicine}
            />
            <SnackBar />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AddMedicineForm;
