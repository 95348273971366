import Login from '../../Login/Login';
import UsersTable from '../../UsersTable/UsersTable';
import AddMedicineForm from '../../Medicines/AddMedicine/AddMedicineForm';
import AddUserForm from '../../AddUserForm/AddUserForm';
import MedicinesTable from '../../Medicines/MedicinesTable/MedicinesTable';
import EditMedicine from '../../Medicines/EditMedicine/EditMedicine';
import UserDetails from '../../UserDetails/UserDetails';
import CreateNewLogin from '../../CreateNewLogin/CreateNewLogin';

const publicRoutes = [
  {
    path: '/login',
    exact: true,
    component: Login,
  },
];

const privateRoutes = [
  {
    path: '/',
    exact: true,
    component: UsersTable,
  },
  {
    path: '/users',
    exact: true,
    component: UsersTable,
  },
  {
    path: '/adduser',
    exact: true,
    component: AddUserForm,
  },
  {
    path: '/createnewlogin',
    exact: true,
    component: CreateNewLogin,
  },
  {
    path: '/medicines',
    exact: true,
    component: MedicinesTable,
  },
  {
    path: '/userdetails',
    exact: true,
    component: UserDetails,
  },
];

export { publicRoutes, privateRoutes };
