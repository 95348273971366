import React, { useState, useEffect } from 'react';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

// styles
import useStyles from './styles';

// logo
import logo from '../../images/logo.png';

// context
// import { useUserDispatch, loginUser } from "../../context/UserContext";

//redux
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';

function Login(props) {
  const classes = useStyles();
  const { userSession } = useSelector((state) => state.users);

  const dispatch = useDispatch();
  //Effects
  useEffect(() => {
    if (!userSession.status) {
      setError(userSession.message);
      setTimeout(() => {
        dispatch(actions.resetUserSessionMessage());
      }, 1);
    }
  }, []);
  // global
  // const userDispatch = useUserDispatch();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTabId, setActiveTabId] = useState(0);
  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.logotypeContainer}>
          <img src={logo} alt="logo" className={classes.logotypeImage} width="170" />
        </div>
        <form
          className={classes.form}
          onSubmit={(event) => {
            event.preventDefault();
            dispatch(
              actions.loginUser(loginValue, passwordValue, props.history)
            );
          }}
        >
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login As Admin" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Fade in={error ? true : false}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error ? error : ''}
                </Typography>
              </Fade>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
        </form>
        <Typography color="primary" className={classes.copyright}>
          © 2020-2022 HelpMyMedicare, LLC. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
