import * as actionTypes from '../actions/constant/actionTypes';

const initialState = {
  isLoading: false,
  failed: false,
  apiResponse: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SERVICE_REQUEST:
      return { ...state, ...action.payload };
    case actionTypes.SERVICE_SUCCESS:
      return { ...state, ...action.payload };
    case actionTypes.SERVICE_ERROR:
      return { ...state, ...action.payload };
    case actionTypes.API_RESPONSE:
      return { ...state, apiResponse: action.payload };
    case actionTypes.RESET_API_RESPONSE:
      return { ...state, apiResponse: {} };
    default:
      return { ...state };
  }
}
