import { get, post, deleteApi } from './api';
import * as actionTypes from './constant/actionTypes';
import * as EndPoint from './constant/EndPoint';

export function apiResponse(responseData) {
  return {
    type: actionTypes.API_RESPONSE,
    payload: {
      status: responseData.status,
      message: responseData.message,
    },
  };
}

export function userMedicines(medicines) {
  return {
    type: actionTypes.GET_USER_MEDICINES,
    medicines: medicines,
  };
}
export function mgRanges(mgRanges) {
  return {
    type: actionTypes.GET_MG_RANGES,
    mgRanges: mgRanges,
  };
}

export function getUserMedicines(user_id) {
  let params = `user_id=${user_id}`;
  return get(EndPoint.GET_USER_MEDICINES, params, userMedicines, null);
}

export function searchMedicines(medicines) {
  return {
    type: actionTypes.SEARCH_MEDICINES,
    searchedMedicines: medicines,
  };
}

export function resetSearchedMedicineData() {
  return {
    type: actionTypes.RESET_SEARCHED_MEDICINE_DATA,
  };
}

export function getSearchedMedicines(inputKeyword,user_id) {
  let params =`search=${inputKeyword.toString()}&user_id=${user_id}`
  return get(EndPoint.SEARCH_MEDICINES, params, searchMedicines, null);
}

export function addUserMedicines(medicineData) {
  let params = medicineData;
  return post(EndPoint.ADD_USER_MEDICINES, params, apiResponse, null);
}
export function deleteUserMedicines(medicineData) {
  let params = medicineData;
  return deleteApi(
    EndPoint.DELETE_USER_MEDICINES,
    '',
    params,
    apiResponse,
    null
  );
}

export function getMgRanges(medicineName) {
  let params = medicineName;
  return get(EndPoint.GET_MG_RANGES, params, mgRanges, null);
}
