import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Table from '../Table/Table';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import { VisibilityTwoTone, SearchOutlined } from '@material-ui/icons';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import SnackBar from '../SnackBar/SnackBar';
import Modals from '../Modal/Modals';
import moment from "moment";
import AlertDialog from '../common/alertDialog';

const UsersTable = (props) => {
  const { usersTable } = useSelector((state) => state.users);
  const { countries } = useSelector((state) => state.dataServices);

  const dispatch = useDispatch();

  const [changedData, setChangedData] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchClientText, setSearchClientText] = useState('');
  const [resetCurrentUser, setResetCurrentUser] = useState({
    open:false,
    currentUser:null
  });


  const handelClose = () => {
    setOpen(false)
  };

  const handleResetClose = () => {
    setResetCurrentUser({
      open:false,
      id:null,
      name:""
    });
  };
  const handleReset = (e,params) => {
      e.preventDefault();
      let user=params.data;
      setResetCurrentUser({
      open:true,
      id:user.id,
      name:`${user.first_name} ${user.last_name}`
    })
  };

  const handleResetConfirm = () => {
    dispatch(actions.resetUser(resetCurrentUser.id));
    setResetCurrentUser({
      open:false,
      id:null,
      name:""
    })
  };

  const getUsersTableData = useCallback(() => {
    dispatch(actions.getUsersTable());
    if (countries.length === 0) {
      dispatch(actions.getCountries());
    }
  }, [countries.length, dispatch]);
  useEffect(() => {
    getUsersTableData();
  }, [getUsersTableData]);
  const columns = [
    {
      headerName: 'Last Name',
      field: 'last_name',
      sortable: true,
      unSortIcon: true,
      cellStyle: { textAlign: 'left' },
      minWidth: 123,
      getQuickFilterText: (params) => params.value,
    },
    {
      headerName: 'First Name',
      field: 'first_name',
      cellStyle: { textAlign: 'left' },
      minWidth: 123,
      getQuickFilterText: (params) => params.value,
    },
    {
      headerName: 'User',
      field: 'email',
      cellStyle: { textAlign: 'left' },
      minWidth: 200,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      cellStyle: { textAlign: 'left' },
      minWidth: 200,
    },
    {
      headerName: 'Changes',
      field: 'is_profile_changed',
      sortable: true,
      unSortIcon: true,
      cellStyle: {
        paddingRight: '2%',
      },
      minWidth: 111,
    },
    {
      headerName: 'What Changed',
      tooltipValueGetter: () => 'See What Changed in Profile',
      cellRendererFramework: (params) =>
        params.data?.what_changed_in_profile ? (
          <div style={{display:'flex',minHeight:'40px'}}>
          <VisibilityTwoTone
            fontSize="small"
            style={{ color: 'blue', cursor: 'pointer', margin:'auto' }}
            onClick={() => {
              let data = params.data.what_changed_in_profile
                ? params?.data?.what_changed_in_profile
                    ?.split(',')
                    .filter((value) => value !== '')
                : [];
              setChangedData(data);
              setOpen(true);
            }}
          />
          </div>
        ) : (
          ''
        ),

      cellStyle: { cursor: 'default' },
      minWidth: 123,
    },
    {
      headerName: 'Ext $50',
      field: 'add_medicine_for_me',
      sortable: true,
      unSortIcon: true,
      cellStyle: { paddingRight: '3%' },
      minWidth: 105,
    },

    {
      headerName: 'Check',
      field: 'payment_via',
      sortable: true,
      unSortIcon: true,
      cellStyle: { paddingRight: '3%' },
      minWidth: 120,
    },
    {
      headerName: 'SS Status',
      field: 'social_benefits_changed_last_year',
      cellStyle: { paddingRight: '3%' },
      minWidth: 100,
    },
    {
      headerName: 'Thinking',
      field: 'thinking_to_add_medicine',
      sortable: true,
      unSortIcon: true,
      cellStyle: { paddingRight: '3%' },
      minWidth: 120,
    },
    {
      headerName: 'DE',
      field: 'check_my_medications',
      sortable: true,
      unSortIcon: true,
      cellStyle: { paddingRight: '4%' },
      minWidth: 100,
    },
    {  headerName: 'date Time',
      field: 'dateTime',
      sortable: true,
      unSortIcon: true,
      cellStyle: { paddingRight: '4%' },
      minWidth: 100,
      sort:"desc",
      hide:true
    },
    {  
      headerName: 'Action',
      cellStyle: { paddingRight: '4%' },
      minWidth: 120,
      cellRendererFramework:(params)=>(
        <Button
          color="secondary"
          style={{ marginBottom: 5 }}
          onClick={(event)=>handleReset(event,params)}>
          Reset
        </Button>
        )
    },
    {
      headerName: 'Last Login',
      field: 'last_login_at',
      minWidth: 200,
      valueFormatter: params => params.data.last_login_at?
    
       moment(params.data.last_login_at).format('DD MMM YY, hh:mm A'):''
       
    },
  ];
  
  
  const rows = [];
  if (usersTable !== undefined && 'data' in usersTable) {
    let newData=[]
    const recentUsers = JSON.parse(localStorage.getItem('recentUsers'));
    if(recentUsers){
      if(recentUsers.length>0){
        const lastThreeUser = recentUsers.slice(Math.max(recentUsers.length - 3, 0))
          newData = lastThreeUser.map((user)=>{
            let found = usersTable.data.find(sd=>sd.id === user.id)
            if(found){
              found.dateTime = user.dateTime
            }
            return found
          })
        }

    }
    
     usersTable.data.map((data) => {
      rows.push({
        last_name: data?.last_name,
        first_name: data?.first_name,
        email: data?.email,
        phone: data?.phone,
        is_profile_changed: !!data?.is_profile_changed ? 'Yes' : 'No',
        what_changed_in_profile: data?.what_changed_in_profile,
        payment_via: data?.payment_via.toLowerCase() === 'check' ? 'Check' : '',
        add_medicine_for_me: !!data?.add_medicine_for_me ? 'Yes' : 'No',
        thinking_to_add_medicine: !!data?.thinking_to_add_medicine
          ? 'Yes'
          : 'No',
        social_benefits_changed_last_year: !!data?.social_benefits_changed_last_year
          ? 'Yes'
          : 'No',
        check_my_medications: !!data?.check_my_medications ? 'No' : 'Yes',
        id: data?.id,
        dateTime:data.dateTime?data.dateTime:'',
        last_login_at:data.last_login_at?data.last_login_at:''
      });
    });
  }
  const defaultColDef = { getQuickFilterText: () => '', resizable: true }
  let modalData = (
    <div>
      <h3>Changes in User's Profile</h3>{' '}
      {changedData?.map((data) => (
        <li key={data}>{data}</li>
      ))}
    </div>
  );
  return (
    <div>
      <Header history={props.history} />
      <Sidebar />
      <Modals open={open} handelClose={handelClose} modalData={modalData} />
      <AlertDialog
        resetCurrentUser={resetCurrentUser}
        handleResetConfirm={handleResetConfirm}
        handleResetClose={handleResetClose}
      />
      <div
        style={{
          marginLeft: '10%',
          marginRight: '10%',
          marginTop: '7.5%',
          width: '85%',
        }}
      >
        <h2 style={{ textAlign: 'left' }}>
          Clients List{' '}
          <Link to="/adduser">
            {' '}
            <Button
              style={{ float: 'right' }}
              color="primary"
              variant="outlined"
            >
              Add Client
            </Button>
          </Link>
          <Link to="/createnewlogin">
            {' '}
            <Button
              style={{
                float: 'right',
                color: 'green',
                borderColor: 'green',
                marginRight: '1%',
              }}
              variant="outlined"
            >
              Create New Login
            </Button>
          </Link>
          <TextField
            placeholder="Search Client"
            variant="outlined"
            type="search"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            style={{ float: 'right', marginRight: '1%' }}
            onChange={(event) => setSearchClientText(event.target.value)}
          />
        </h2>
        <Table
          rows={rows}
          columns={columns}
          redirect={true}
          link={'/userdetails'}
          filterText={searchClientText}
          defaultColDef={defaultColDef}
        />
        <SnackBar />
      </div>
    </div>
  );
};
export default UsersTable;
